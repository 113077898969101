import * as React from 'react';
import { graphql, Link } from 'gatsby';

import Layout from '../../components/layout';
import Seo from '../../components/seo';
import Picblock from '../../components/picblock';
import * as styles from '../../components/styles/index.module.css';

export default function SDDataProfiling({ data }) {
  const picdata = data.allMarkdownRemark.nodes;

  return (
    <Layout>
      <Seo title="Data Profiling Services" />
      <div className={styles.impact}>
        Data profiling is a great place to start by understanding the quality of your existing data.
      </div>
      <div className={styles.calltoaction}>
        Request your free data profile to ensure you get the benefits now.
        <div>
          <Link to="/profiler">
            <button className={styles.contact}>
              Request Profile
            </button>
          </Link>
        </div>
      </div>
      { picdata.map((data) => (
        <Picblock data={data} key={data.id} />
      ))}
    </Layout>
  );
}

export const query = graphql`
query dataprofilepage {
    allMarkdownRemark(
      sort: {fields: frontmatter___sortorder, order: ASC}
      filter: {frontmatter: {page: {eq: "servicedataprofile"}}}
    ) {
      nodes {
        id 
        frontmatter {
          page
          pictitle
          sortorder
          title
          imageside
          imageurl {
            childImageSharp {
              gatsbyImageData(layout: FIXED)
            }
          }
        }
        html
      }
    }
  }
  
`;

// TODO:  You can publish a test website with surge.sh.
